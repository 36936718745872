import React from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { SectionHeader, SectionWrapper } from "./shared";
import { config, experiences, TExperience } from "../../models";
import { styles } from "../../styles";

const ExperienceCard: React.FC<TExperience> = (experience) => {
    return (
        <VerticalTimelineElement
            contentStyle={{
                background: "#1d1836",
                color: "#fff",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #232631" }}
            date={experience.date}
            iconStyle={{ background: experience.iconBg }}
            icon={
                <div className="flex h-full w-full items-center justify-center">
                    <img
                        src={experience.icon}
                        alt={experience.companyName}
                        className="h-[60%] w-[60%] object-contain"
                    />
                </div>
            }
        >
            <div>
                <h3 className={styles.h3}>{experience.title}</h3>
                <p
                    className="text-secondary text-[16px] font-semibold"
                    style={{ margin: 0 }}
                >
                    {experience.companyName}
                </p>
            </div>

            <ul className="ml-5 mt-5 list-disc space-y-2">
                {experience.points.map((point, index) => (
                    <li
                        key={`experience-point-${index}`}
                        className="text-white-100 pl-1 text-[14px] tracking-wider"
                    >
                        {point}
                    </li>
                ))}
            </ul>
        </VerticalTimelineElement>
    );
};

const Experience = () => {
    return (
        <>
            <SectionHeader useMotion={true} {...config.sections.experience} />

            <div className="mt-20 flex flex-col">
                <VerticalTimeline className="!m-0 !w-full">
                    {experiences.map((experience, index) => (
                        <ExperienceCard key={index} {...experience} />
                    ))}
                </VerticalTimeline>
            </div>
        </>
    );
};

export default SectionWrapper(Experience, "experience");
