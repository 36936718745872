import { crossFunctional, erp, fullstack, saas } from "../assets";

type TSkill = {
    expertise: TExpertise;
    technicals: TTechnical;
};

type TExpertiseItem = {
    title: string;
    icon: string;
};

type TTechnicalItem = {
    title: string;
    items: string[];
};

type TExpertise = {
    title: string;
    description: string[];
    additional: string[];
    items: TExpertiseItem[];
};

type TTechnical = {
    title: string;
    items: TTechnicalItem[];
};

export const skills: TSkill = {
    expertise: {
        title: "Areas of Expertise",
        description: [
            "While my expertise primarily lies with being a Full Stack .NET developer using AWS and C# for the backend and TypeScript and React for the front-end, I have cross-functional expertise in areas across and beyond the Software Development Lifecycle including:",
        ],
        additional: [
            "Product, user and technical research",
            "Requirements gathering and analysis",
            "Writing complex design and functional specifications",
            "Code reviews and merging",
            "DevOps and Release Management",
            "Quotations and budgeting",
            "Client and resource co-ordination",
            "Training and mentoring junior developers",
            "Showcasing project work during sprint reviews",
            "Running daily sprint standups",
        ],
        items: [
            {
                title: "Full Stack .NET Developer",
                icon: fullstack,
            },
            {
                title: "ERP Software Domain",
                icon: erp,
            },
            {
                title: "AWS SaaS applications",
                icon: saas,
            },
            {
                title: "Cross functional roles",
                icon: crossFunctional,
            },
        ],
    },
    technicals: {
        title: "Technicals",
        items: [
            {
                title: "Frameworks",
                items: [
                    ".NET 8",
                    "MVC",
                    "WebApi",
                    "Microservices",
                    "RabbitMQ",
                    "SPA",
                    "SOA",
                    "DDD",
                    "TDD - NUnit",
                    "CI/CD - TeamCity",
                    "Entity Framework",
                ],
            },
            {
                title: "Languages",
                items: [
                    "C#",
                    "Java",
                    "JavaScript - TypeScript, React, Redux, RxJs, Node, Jest, Webpack",
                    "CSS - Tailwind, Less, Sass",
                    "Python",
                    "SQL",
                    "PowerShell",
                    "Bash",
                ],
            },
            {
                title: "Databases",
                items: [
                    "SQL Server",
                    "Progress",
                    "PostgreSql",
                    "MongoDb",
                    "Redis",
                ],
            },
            {
                title: "Cloud (AWS)",
                items: [
                    "Serverless - Lambda, Fargate",
                    "Storage - S3, DynamoDb",
                    "Containers - ECS, ECR",
                    "Integrations - API Gateway, EventBridge, SNS, SQS",
                    "Security - Cognito, IAM",
                ],
            },
        ],
    },
};
