import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AutoScroll = () => {
    const location = useLocation();

    // only execute when first mounting, so can auto-scroll to a section when first opening
    // the page if a hash location has been provided in the URL
    useEffect(() => {
        // Check if there's a hash in the URL
        const hash = location.hash;

        if (hash) {
            const element = document.querySelector(hash);

            if (element) element.scrollIntoView({ behavior: "smooth" });
        }
    });

    return null;
};

export default AutoScroll;
