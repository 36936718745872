import { TCommonProps } from "./common";
import {
    aecom,
    holidays,
    markinson,
    myp,
    newCollege,
    qut,
    technologyone,
} from "../assets";

export type TExperience = {
    companyName: string;
    iconBg: string;
    date: string;
    points: string[];
} & Required<Omit<TCommonProps, "name">>;

export const experiences: TExperience[] = [
    {
        title: "Career break | Sabbatical",
        companyName:
            "Taking the opportunity to see the world and emigrate overseas",
        icon: holidays,
        iconBg: "#99FAFF",
        date: "April 2024 - September 2024",
        points: [
            "Travelled the world visiting places I've always wanted to see",
            "Completed A Cloud Guru's AWS Solutions Architect - Associate course",
            "Completed my 2D Simulation personal project",
            "Built My Portfolio website",
            "Gaining experience with CFD trading",
            "Fulfilling my dream of living and working overseas",
        ],
    },
    {
        title: "Senior Developer | Assoc. Architect",
        companyName: "TechnologyOne",
        icon: technologyone,
        iconBg: "#F7EEED",
        date: "September 2017 - April 2024",
        points: [
            "Co-developed a hybrid web application for Higher Education students to support their course and study management. Technologies included C#, TypeScript, React and Redux and served using AWS DynamoDb, S3 and Lambda, delivering a robust, scalable and highly available cloud solution",
            "Architected major enhancements to Debtor Management functionality to support bulk and partial transaction adjustments to improve operational efficiency, involving 6 months of development work",
            "Optimised data migration processes to handle data volumes in the millions, where bottlenecks had become a dealbreaker for some clients, by reducing runtime by 98% from 50+ hours down to 1 hour; the client and senior leadership team were thrilled with such an impressive turnaround",
            "Led a major GitLab workflow improvement project across 3 product teams, to take advantage of BitBucket cascade merging to streamline the development process for 12+ developers",
            "Led the design for integrating Request Management and Student Management in-house products, to enable students to lodge cases and support Payment Gateway integration",
            "DevOps responsibilities including managing the team's CI/CD build channels in TeamCity, code reviews/merging, patching back to prior releases, hotfixes and production data scripting",
            "Showcasing new features during sprint review and engaging with the consulting/customer teams",
            "Running daily sprint standup's and active involvement in sprint planning, triaging and backlog refinement",
        ],
    },
    {
        title: "Web Application Developer",
        companyName: "MYP Corporation",
        icon: myp,
        iconBg: "#F7EEED",
        date: "August 2016 - August 2017",
        points: [
            "Worked closely with the client CEO of an insurance company, and the CEO of MYP to deliver a $100,000+ project by enhancing in-house software to build out the workflow for generating insurance policies, and developing a management dashboard, supporting various user roles, for staff, brokers and underwriters. Technologies included React, Redux, C#, MVC and SQL Server",
            "Completed all outstanding development work that was preventing MYP’s in-house WYSIWYG builder formSPACE from being released, delivering a superior UX focused on empowering users",
            "Scheduled release engineer to deploy all company updates to their web application",
            "Won MYP’s WOW award for exceptional performance and continually exceeding client expectations",
        ],
    },
    {
        title: "ERP Software Developer | Consultant",
        companyName: "Markinson Business Solutions",
        icon: markinson,
        iconBg: "#F7EEED",
        date: "August 2010 - August 2016",
        points: [
            "Co-developed a Windows Mobile native offline application used by large wholesalers for stocktaking, picking and packing of goods, and other warehouse functions such as stock enquiry and bin assignment. Application built using Resco UI controls, SQLite for local storage and web services for ERP API integration",
            "Architected a high-performant document automation workflow from the ERP software using the Bottomline Transform Suite, capable of transmitting 50+ business documents per second",
            "Worked solely with a major client during a go-live weekend to deliver various ETL tasks for a significant merger of client businesses; the client was very impressed with the successful outcome, and my ability to accommodate their needs",
            "Worked with 20+ client organisations on a number of ERP integration projects including: B2BE EDI, SimPro, SAP OCI, ZAP BI, QlikView, Infor CRM, Joomla, IFS SmartFreight, StarTrack Express, Barcode/Despatch printing, PayPal, Statement run transmission and Banking auto-payments",
            "Top fee earner in the division consistently maintaining a billing utilisation rate of at least 80%",
            "Trained and mentored a total of 8 graduate developers to build-up a successful and motivated team",
            "Won the Positive Partnership award for being an ultimate team player, versatile and adaptable",
            "Won the Inspiring Individual award for delivering high quality even under immense time pressure",
        ],
    },
    {
        title: "Assistant Financial Accountant",
        companyName: "AECOM",
        icon: aecom,
        iconBg: "#F7EEED",
        date: "June 2008 - August 2010",
        points: [
            "Bookkeeping in the General, Purchases and Sales ledgers",
            "Preparation of monthly financial statements and analysis of reporting figures",
            "Maintenance of the Fixed Asset Register, Debtor's Aging and Cash flow forecast",
            "Bank and credit card reconciliation, and domestic and international payments",
        ],
    },
    {
        title: "B.Bus | B.IT",
        companyName: "Queensland University of Technology",
        icon: qut,
        iconBg: "#99FAFF",
        date: "February 2006 - November 2009",
        points: [
            "Graduated with a distinction in IT",
            "Accounting and Database Design majors",
            "Multi-core CPU's and Parallel programming capstone project",
            "Academic transcript can be provided on request",
        ],
    },
    {
        title: "Sixth Form",
        companyName: "New College Swindon",
        icon: newCollege,
        iconBg: "#99FAFF",
        date: "August 2004 - February 2006",
        points: [
            "Maths - Distinction",
            "Accounting - Credit",
            "Business - Pass",
            "Computing - Pass",
        ],
    },
];
