import { motion } from "framer-motion";

import { styles } from "../../../styles";

interface Props {
    Component: React.ElementType;
    idName: string;
}

const SectionWrapper = (
    Component: Props["Component"],
    idName: Props["idName"]
) =>
    function HOC() {
        // IMPORTANT to avoid setting a viewport amount, because for large sections such as Experience,
        // the % of viewport visible for the heading to display may be impossible to display, especially for
        // mobile devices, hence the section heading animation doesn't initiate
        return (
            <motion.section
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
                className={`${styles.padding} relative z-0 mx-auto max-w-7xl`}
                id={idName}
            >
                {/* Maintains a gap at the top of each section, so when navigating to a section, the section
		    heading does not disappear behind the NavBar */}
                <span className="hash-span">&nbsp;</span>

                <Component />
            </motion.section>
        );
    };

export default SectionWrapper;
