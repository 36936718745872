import React from "react";
import { motion } from "framer-motion";

import { styles } from "../../../styles";
import { textVariant } from "../../../utils/motion";

interface ISectionHeader {
    useMotion: boolean;
    p: string;
    h2: string;
}

export const SectionHeader: React.FC<ISectionHeader> = ({
    useMotion,
    p,
    h2,
}) => {
    const Content = () => (
        <>
            <p className={styles.sectionSubText}>{p}</p>
            <h2 className={styles.sectionHeadText}>{h2}</h2>
        </>
    );

    // See comment in SectionWrapper for what can cause the section header animation to not work
    // for large sections
    return useMotion === true ? (
        <motion.div variants={textVariant()}>
            <Content />
        </motion.div>
    ) : (
        <Content />
    );
};
